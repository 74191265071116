import React from "react";
import { useState, useEffect } from "react";
import Featured1 from "../images/Here_the_are-removebg-preview.jpg";
import Featured2 from "../images/She_can_do_it-removebg-preview.jpg";
import Featured3 from "../images/Womens_group1-removebg-preview.jpg";
// import { BsFillPlayCircleFill } from "react-icons/bs";
import "./Home.css";
import CountUp from "react-countup";
import ReactVisibilitySensor from "react-visibility-sensor";
import Footer from "./footer/Footer";
import Homeblog from "./Homeblog";

const Home = ({ className, ...rest }) => {
  const [viewPortEntered, setViewPortEntered] = useState(false);

  const workInfoData = [
    {
      image: Featured1,
      title: "Tutoring",
      text: "We empower young women through coding education for them to have a supportive community that will inspire the next generation of female technological leaders.",
    },
    {
      image: Featured2,
      title: "Peer-To-Peer Events",
      text: "We are dedicated to providing girls with a safe space to learn from one another, to share resources in developing technological skills.",
    },
    {
      image: Featured3,
      title: "Volunteer Work",
      text: "Volunteering at local organizations and hosting fundraisers for charitable causes.",
    },
  ];

  const slides = [
    {
      img: Featured1,
      title: "Welcome to She Debugs and Encourage Her",
      text: "She Debugs and Encourage Her are clubs dedicated to empowering young women within our community.",
      bgColor: "#3FC1C9",
    },
    {
      img: Featured2,
      title: "What We Believe",
      text: "We believe in the extraordinary potential of women and are committed to providing the support, resources, and inspiration needed to reach their full potential. ",
      bgColor: "#FF7F50",
    },
    {
      img: Featured3,
      title:
        "Join us as we create a world where women thrive and make a lasting impact.",
      text: "Through mentor-ship, education, and collaborative experiences, we aim to foster a generation of confident, capable, and compassionate leaders.",
      bgColor: "#800080",
    },
  ];

  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const slideInterval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, 5000);

    return () => clearInterval(slideInterval);
  }, [slides.length]);

  const handlePreviousClick = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? slides.length - 1 : prevSlide - 1
    );
  };

  const handleNextClick = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
  };
  return (
    <>
      <div className="slider-container">
        <div
          className="slider"
          style={{ transform: `translateX(${-currentSlide * 100}%)` }}
        >
          {slides.map((slide, index) => (
            <div
              className="slide"
              key={index}
              style={{ backgroundColor: slide.bgColor }}
            >
              <img
                src={slide.img}
                alt={`Slide ${index + 1}`}
                className="slide-img"
              />
              <div className="slide-content">
                <h2 className="slide-title">{slide.title}</h2>
                {slide.text && <p className="slide-text">{slide.text}</p>}
                {/* <button className="learn-more">Learn More</button> */}
              </div>
            </div>
          ))}
        </div>
        <button className="prev" onClick={handlePreviousClick}>
          &#10094;
        </button>
        <button className="next" onClick={handleNextClick}>
          &#10095;
        </button>
      </div>
      <section className="counter">
        <div className="title">
          <h1>Creating Young Women Who Stand In Their Power!</h1>
          <p>
            The mission of Encourage Her is to empower women to embrace their
            full potential, cultivate self-confidence, and thrive in every
            sphere of life. We are pledged to shared experience, life-skills
            discussions, fund-raising activities for women in need, and a
            community of supporters from within the club. We collectively dream
            of a world in which women are celebrated, empowered, and
            unstoppable.
          </p>
          <p>
            The mission of She-Debugs Club is to empower young women through
            coding education for them to have a supportive community that will
            inspire the next generation of female technological leaders.
          </p>
          <p>
            We are dedicated to providing girls with a safe space to learn from
            one another, to share resources in developing technological skills.
          </p>
        </div>

        <div className="counter-row">
          <div className="counter-column">
            <strong data-number="310">
              <CountUp {...rest} start={viewPortEntered ? null : 0} end={4500}>
                {({ countUpRef }) => {
                  return (
                    <ReactVisibilitySensor
                      active={!viewPortEntered}
                      onChange={(isVisible) => {
                        if (isVisible) {
                          setViewPortEntered(true);
                        }
                      }}
                      delayedCall
                    >
                      <span className="number" ref={countUpRef} />
                    </ReactVisibilitySensor>
                  );
                }}
              </CountUp>
            </strong>
            <span>Birr raised for volunteer organizations.</span>
          </div>

          <div className="counter-column">
            <strong data-number="1250">
              <CountUp {...rest} start={viewPortEntered ? null : 0} end={15}>
                {({ countUpRef }) => {
                  return (
                    <ReactVisibilitySensor
                      active={!viewPortEntered}
                      onChange={(isVisible) => {
                        if (isVisible) {
                          setViewPortEntered(true);
                        }
                      }}
                      delayedCall
                    >
                      <span className="number" ref={countUpRef} />
                    </ReactVisibilitySensor>
                  );
                }}
              </CountUp>
            </strong>
            <span>
              <h1>+</h1> girls equipped with coding fundamentals.
            </span>
          </div>

          <div className="counter-column">
            <strong data-number="125">
              <CountUp {...rest} start={viewPortEntered ? null : 0} end={40}>
                {({ countUpRef }) => {
                  return (
                    <ReactVisibilitySensor
                      active={!viewPortEntered}
                      onChange={(isVisible) => {
                        if (isVisible) {
                          setViewPortEntered(true);
                        }
                      }}
                      delayedCall
                    >
                      <span className="number" ref={countUpRef} />
                    </ReactVisibilitySensor>
                  );
                }}
              </CountUp>
            </strong>
            <span>
              {" "}
              <h1>+</h1>young women empowered with life skills.
            </span>
          </div>
        </div>
      </section>
      <Homeblog />
      <div className="work-section-wrapper">
        <div className="work-section-top">
          <p className="work-primary-subheading">Featured</p>
          <h1 className="work-primary-heading">Featured Programs</h1>
          <p className="work-primary-text">Our Programs</p>
        </div>
        <div className="work-section-bottom">
          {workInfoData.map((data) => (
            <div className="work-section-info" key={data.title}>
              <div className="info-boxes-img-container">
                <img src={data.image} alt="" />
              </div>
              <h2>{data.title}</h2>
              <p>{data.text}</p>
            </div>
          ))}
        </div>
      </div>

      <div className="newsletter-section">
        <div className="newsletter-subscription">
          <h2>Join us in fostering hope and opportunities for women</h2>
          <p>
            Get the latest news about our projects, inspiring stories about the
            resilient women we serve, upcoming events, and how you can make a
            difference!
          </p>
          <button
            type="button"
            className="btn subscribe"
            onClick={() => (window.location.href = "https://t.me/EncourageHer")}
          >
            Join
          </button>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Home;
