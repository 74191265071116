import { useState } from "react";
import Navbar from "./Navbar";
import { Link } from "react-router-dom";
import logo from "../images/Logo2.jpg";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header>
      <div className="nav-area">
        <Link to="/" className="logo">
          <img
            src={logo}
            alt="logo"
            width="80px"
            height="80px"
            border-radius="50px"
          />
        </Link>
        <button className="menu-bar-icon" onClick={toggleMenu}>
          &#9776; {/* Unicode character for hamburger menu icon */}
        </button>
        <Navbar isMenuOpen={isMenuOpen} />
      </div>
    </header>
  );
};

export default Header;
