import React, { useState } from "react";
import { blog } from "../components/dummyData";
import Footer from "./footer/Footer";
import Popup from "../components/PopUp";
import "./Blogs.css";

// Function to remove HTML tags
const stripHtmlTags = (text) => {
  const div = document.createElement("div");
  div.innerHTML = text;
  return div.textContent || div.innerText || "";
};

const Blogs = () => {
  const [selectedPost, setSelectedPost] = useState(null);

  const handleExpand = (post) => {
    setSelectedPost(post);
  };

  const handleClose = () => {
    setSelectedPost(null);
  };

  return (
    <>
      <div className="blog-page">
        <h1 className="page-title">Blogs</h1>
        <div className="blog-list">
          {blog.map((post) => (
            <div key={post.id} className="blog-card">
              <img src={post.cover} alt={post.title} className="blog-image" />
              <div className="blog-content">
                <div className="blog-meta">
                  <span className="blog-type">{post.type}</span>
                  <span className="blog-date">{post.date}</span>
                  <span className="blog-comments">{post.com}</span>
                </div>
                <h2 className="blog-title">{post.title}</h2>
                <p className="blog-desc">{stripHtmlTags(post.desc)}</p>{" "}
                {/* Strip HTML tags */}
                <button
                  className="expand-button"
                  onClick={() => handleExpand(post)}
                >
                  Expand
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
      {selectedPost && <Popup post={selectedPost} onClose={handleClose} />}
      <Footer />
    </>
  );
};

export default Blogs;
