import React from "react";
import Image1 from "../images/Eldana Cover.jpg";
import { FaUser, FaBullseye, FaBriefcase, FaEye } from "react-icons/fa";
import Footer from "./footer/Footer";

import "./About.css";

const About = () => {
  return (
    <>
      <div className="about-container">
        <img src={Image1} alt="Empowering Women" className="center-image" />
        <h1>
          About Me <FaUser className="icon" />
        </h1>

        <p>
          Recognizing a significant gender gap in both life skills and
          technology fields within my community, I, Eldana M. Assefa,
          established two clubs: Encourage-Her and She-debugs. Driven by a
          passion for empowering women, I sought to create platforms that would
          inspire, support, and equip women with the tools they need to reach
          their full potential. My goal was to foster a community where women
          could thrive, overcome challenges, and contribute meaningfully to
          society.
        </p>

        <p>
          When founding Encourage-Her, I envisioned a platform that would not
          only inspire young women but also provide them with the necessary
          resources, knowledge, and support to overcome societal barriers and
          realize their aspirations. Within the club, we shared experiences,
          offered peer-to-peer mentorship on life skills, gender equality, and
          women empowerment, and even fundraised and donated to other volunteer
          organizations. These initiatives aimed to dismantle deeply ingrained
          backward thinking that hindered women's progress and create a more
          inclusive and empowering environment.
        </p>

        <p>
          As time went on, I realized the need to establish another club,
          She-debugs, which empowers women in technology fields and
          extracurriculars. After reading about the low participation of women
          in the technology industry in Ethiopia and globally, I initiated
          She-debugs to inspire girls around me in the tech field. Within
          She-Debugs, we learned and discussed programming languages like HTML,
          CSS, C++ & Python, and shared resources through peer-to peer
          tutorials..
        </p>

        <p>
          Today, the clubs I founded stand as a testament to my unwavering
          belief that every woman deserves equal rights, opportunities, and
          support. We remain dedicated to challenging and reshaping societal
          norms, fostering a strong sense of sisterhood, and working towards a
          future where women are empowered to break free from limitations and
          achieve their dreams.
        </p>

        <p>
          Our journey is far from over. We're deeply committed to lifting women
          up and making the world a better place. We want to be the spark that
          ignites change, inspiring others to join us in creating a world where
          everyone has a fair shot. We're determined to break down walls,
          celebrate women's achievements, and open doors for countless
          opportunities. Together, we can build a future where women thrive.
        </p>
        <h1>
          Our mission is to: <FaBullseye className="icon" />
        </h1>

        <p>
          Awaken women within our community to their rights and the importance
          of gender equality, highlighting women’s vital roles in society.
          Empower young women through technological skills such as coding,
          mentor-ship, and community-building, inspiring them to become
          confident and innovative technology leaders. Support women-focused
          initiatives by fund-raising and donating to charitable causes. Foster
          a strong network of women through networking and mentor-ship events,
          providing opportunities for growth and connection. Facilitate access
          to opportunities for women by sharing information about intern-ships,
          empowerment events, and other relevant resources.
        </p>
        <h1>
          Our Work <FaBriefcase className="icon" />
        </h1>

        <p>
          At Encourage-Her club, we are committed to providing support to young
          women within our community. We strive to offer peer to peer sharing of
          experiences, mentor-ship programs, and financial assistance to empower
          women facing challenges. Through strategic fund-raising efforts, we
          donate to vetted non-profit organizations that implement economic
          empowerment initiatives for women experiencing poverty. Together, we
          make a lasting impact by uplifting and empowering women, helping them
          overcome obstacles and thrive. At She-Debugs, we motivate young women
          through technology skills such as coding and peer-to-peer tutorials.
          We aim to provide a supportive community where girls can learn, grow,
          and share essential technology skills. By offering access to coding
          resources and peer support, we aspire to cultivate a future generation
          of women technology leaders. She-Debugs is committed to bridging the
          gender gap in technology and fostering a diverse and inclusive
          technological ecosystem.
        </p>
        <h1>
          Our vision <FaEye className="icon" />
        </h1>

        <p>
          Creating a community of empowered women who reach their full potential
          and A world where women are equally represented and excel in the
          technology industry.
        </p>
      </div>
      <Footer />
    </>
  );
};

export default About;
