import React from "react";
import "./PopUp.css"; // Add CSS file for styling

const PopupCard = ({ post, onClose }) => {
  return (
    <div className="popup-overlay">
      <div className="popup-card">
        <button className="popup-close" onClick={onClose}>
          ×
        </button>
        <img src={post.cover} alt={post.title} className="popup-image" />
        <div className="popup-content">
          <h2 className="popup-title">{post.title}</h2>
          <div
            className="popup-desc"
            dangerouslySetInnerHTML={{ __html: post.desc }}
          />
        </div>
      </div>
    </div>
  );
};

export default PopupCard;
