export const menuItems = [
  {
    title: "About Us",
    url: "/about",
  },
  {
    title: "Get involved",
    url: "/our-story",
  },
  {
    title: "Blogs",
    url: "/blogs",
  },

  {
    title: "Contact",
    url: "/contact",
  },
];
