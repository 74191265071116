import React from "react";
import Footer from "./footer/Footer";
import "./OurStory.css";
import Image1 from "../images/Womens_group1-removebg-preview.jpg";

function About() {
  return (
    <>
      <div className="about-box">
        <div className="our-story">
          <div className="header-section">
            <h1> Make a meaningful impact today!</h1>
            <p>
              Are you passionate about women's empowerment? Do you believe in
              creating a more inclusive and equal society? We invite you to host
              an event that aligns with the values and mission of our Women
              Empowerment Club! By hosting an event, you have the opportunity to
              make a real impact and inspire others to join our cause. <br />
              <br /> Whether it's a panel discussion, workshop, fund-raising, or
              any other creative idea, you can create a platform to raise
              awareness and support women's empowerment initiatives and their
              involvement in the technological industry. If you are interested
              in hosting an event and would like to collaborate with us, please
              reach out to us at us at{" "}
              <a
                href="mailto:eldana.assefa149@gmail.com"
                style={{ textDecoration: "none", color: "Blue" }}
              >
                <em>My Email</em>
              </a>
              . We would be thrilled to discuss your ideas and provide any
              assistance you may need to make your event a success. Contact us
              today and let's create a powerful event that makes a difference!
            </p>
          </div>
          <div className="about-data">
            <div className="about-img">
              <img src={Image1} alt="Our Impact" />
            </div>
            <div className="about-tdiv">
              <p className="about-text">
                Your donation has the power to transform lives and create
                positive change. By contributing to our fund-raising efforts,
                you are directly supporting women in need and helping them
                overcome challenges. Together, we can make a difference and
                uplift those who need it most.
                <br />
                <br />
                Every donation, no matter the amount, is valuable and
                appreciated. Your generosity will have a lasting impact on the
                lives of women in need.
                <br />
                <br />
                To donate or learn more about our cause, please contact us at{" "}
                <a
                  href="mailto:eldana.assefa149@gmail.com"
                  style={{ textDecoration: "none", color: "Blue" }}
                >
                  <em>My Email</em>
                </a>
                . Together, let's create a brighter future for women and
                communities.
                <br />
                <br />
                Donate today and be a catalyst for change. Thank you for your
                support!
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default About;
