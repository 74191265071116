import React from "react";
import { Link } from "react-router-dom";
import { FaMapMarkerAlt, FaEnvelope, FaTelegramPlane } from "react-icons/fa";
import { FiChevronRight } from "react-icons/fi";
import "./footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-section">
          <h3>Follow Us</h3>
          <p>Follow us on social media and stay connected!</p>
          <div className="social-icons">
            <a href="https://t.me/EncourageHer" aria-label="Telegram">
              <FaTelegramPlane />
            </a>
          </div>
        </div>
        <div className="footer-section">
          <h3>Important Links</h3>
          <ul>
            <li>
              <Link to="/about">
                <FiChevronRight /> About Us
              </Link>
            </li>
            <li>
              <Link to="/blogs">
                <FiChevronRight /> Blogs
              </Link>
            </li>
            <li>
              <Link to="/our-story">
                <FiChevronRight /> Our Story
              </Link>
            </li>
            <li>
              <Link to="/contact">
                <FiChevronRight /> Contact
              </Link>
            </li>
          </ul>
        </div>

        <div className="footer-section">
          <h3>Contact Us</h3>
          <address>
            <p>
              <FaMapMarkerAlt />
              Dilla, Ethiopia
            </p>
            {/* <p>
              <FaPhoneAlt /> 619-333-0026
            </p> */}
            <br />
            <p>
              <FaEnvelope />{" "}
              <a href="mailto:eldana.assefa149@gmail.com">Email Us</a>
            </p>
          </address>
        </div>
        <div className="footer-section">
          <h3>Subscribe</h3>
          <p>Click to join our telegram channel</p>
          <button
            type="button"
            className="subscribe-button"
            onClick={() => (window.location.href = "https://t.me/EncourageHer")}
          >
            Join
          </button>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
